import React from "react";

const Banner = (props) => {
  const closeBannerHandler = () => props.closeBanner();

  return (
    <div className={`banner ${props.className ? "banner--active" : ""}`}>
      <p>
        I'm currently working as an Application Security Engineer at{" "}
        <a href="https://www.uala.com.ar/" className="banner__link">
          Ualá
        </a>
        . 💻 🔐
      </p>
      <i className="fas fa-times" onClick={closeBannerHandler}></i>
    </div>
  );
};

export default Banner;
