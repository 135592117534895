import React, { useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";

import Header from "../../UI/Header";
import Traits from "./Traits";
import IconContainer from "./IconList";

import {
  operatingSystems,
  programmingLanguages,
  technologies,
} from "../../../Data";
import AboutContainer from "./AboutContainer";
import Vignettes from "./Vignettes";
import Languages from "./Languages";

const About = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);

  return (
    <section className="about" id="about">
      <Header
        title="Get to know me"
        subtitle="about me"
        className={"portfolio-heading"}
      />

      <main className="info-container">
        <Vignettes />

        <AboutContainer
          title="Some of my traits"
          className="traits"
          shape="1"
          animation="fade-left"
        >
          <Traits />
        </AboutContainer>

        <AboutContainer
          title="Programming Languages"
          className="programming-languages"
          shape="2"
          animation="fade-right"
        >
          <IconContainer list={programmingLanguages} />
        </AboutContainer>

        <AboutContainer
          title="Operating Systems"
          className="operating-systems"
          shape="1"
          animation="fade-left"
        >
          <IconContainer list={operatingSystems} />
        </AboutContainer>

        <AboutContainer
          title="Technologies I Use"
          className="technologies"
          shape="2"
          animation="fade-right"
        >
          <IconContainer list={technologies} />
        </AboutContainer>
        
        <Languages />
      </main>
    </section>
  );
};

export default About;
