import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="main-content">
        <p>&copy;Copyright 2022 | Santiago Passafiume</p>
        <p>
          Created by{" "}
          <a
            className="footer-link"
            target={"_blank"}
            rel="noopener noreferrer"
            href="https://deborabucci.com/"
          >
            Débora Bucci
          </a>
        </p>
      </div>
      <p className="credits-box">
        Some of the page's icons were created by
        <a
          className="credits"
          target={"_blank"}
          rel="noopener noreferrer"
          href="https://www.flaticon.com/authors/freepik"
          title="page's icon"
        >
          Freepik - Flaticon
        </a>
        and
        <a
          className="credits"
          target="_blank"
          rel="noopener noreferrer"
          href="https://icons8.com"
        >
          Icons8
        </a>
      </p>
    </footer>
  );
};

export default Footer;
