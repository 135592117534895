import React from "react";

const AboutContainer = ({ title, className, shape, children, animation }) => {
  return (
    <div
      className={`about__${className} u-center-text poly-shape--${shape} about__container`}
      data-aos={animation}
    >
      <h3 className="u-mbm">{title}</h3>
      {children}
    </div>
  );
};

export default AboutContainer;
