import Course01 from "./imgs/courses/course--11.webp";
import Course02 from "./imgs/courses/course--10.webp";
import Course03 from "./imgs/courses/course--9.webp";
import Course04 from "./imgs/courses/course--8.webp";
import Course05 from "./imgs/courses/course--7.webp";
import Course06 from "./imgs/courses/course--6.webp";
import Course07 from "./imgs/courses/course--5.webp";
import Course08 from "./imgs/courses/course--4.webp";
import Course09 from "./imgs/courses/course--3.webp";
import Course10 from "./imgs/courses/course--2.webp";
import Course11 from "./imgs/courses/course--1.webp";

// ICONS
// -----------------------------------------------------------------
import JavaScript from "./imgs/technologies-icons/javascript.svg";
import HTML5 from "./imgs/technologies-icons/html5.svg";
import CSS3 from "./imgs/technologies-icons/css3.svg";
import GIT from "./imgs/technologies-icons/git.svg";
import GitHub from "./imgs/technologies-icons/github.png";
import NodeJS from "./imgs/technologies-icons/nodejs.svg";
import NPM from "./imgs/technologies-icons/npm.svg";
import Postman from "./imgs/technologies-icons/postman.png";
import Python from "./imgs/technologies-icons/python.svg";
import MongoDB from "./imgs/technologies-icons/mongodb.svg";
import Express from "./imgs/technologies-icons/express.png";
import Linux from "./imgs/technologies-icons/linux.svg";
import WireShark from "./imgs/technologies-icons/wireshark.png";
import Rust from "./imgs/technologies-icons/rust.svg";
import SQL from "./imgs/technologies-icons/sql.svg";
import BurpSuite from "./imgs/technologies-icons/burpsuite.png";
import KaliLinux from "./imgs/technologies-icons/kalilinux.png";
import Windows from "./imgs/technologies-icons/windows.svg";
import Docker from "./imgs/technologies-icons/docker.svg";
import Kubernetes from "./imgs/technologies-icons/kubernetes.svg";
import C from "./imgs/technologies-icons/c.svg";
import Bash from "./imgs/technologies-icons/bash.svg";
import Android from "./imgs/technologies-icons/android.svg";
import Swift from "./imgs/technologies-icons/swift.svg";
import Ios from "./imgs/technologies-icons/ios.svg";
import Okta from "./imgs/technologies-icons/okta.svg";
import Macos from "./imgs/technologies-icons/macos.svg";
import Auth0 from "./imgs/technologies-icons/auth0.png";
import Aws from "./imgs/technologies-icons/aws.svg";
import Gcp from "./imgs/technologies-icons/gcp.svg";
import Xcode from "./imgs/technologies-icons/xcode.svg";

import Italian from "./imgs/italian.png";
import Spanish from "./imgs/spanish.png";
import English from "./imgs/english.png";

const technologies = [
  {
    icon: NodeJS,
    text: "Node.js",
  },
  {
    icon: Express,
    text: "Express",
  },
  {
    icon: NPM,
    text: "NPM",
  },
  {
    icon: MongoDB,
    text: "MongoDB",
  },
  {
    icon: HTML5,
    text: "HTML5",
  },
  {
    icon: CSS3,
    text: "CSS3",
  },
  {
    icon: BurpSuite,
    text: "Burp Suite",
  },
  {
    icon: GIT,
    text: "GIT",
  },
  {
    icon: GitHub,
    text: "GitHub",
  },
  {
    icon: Postman,
    text: "Postman",
  },
  {
    icon: SQL,
    text: "SQL",
  },
  {
    icon: WireShark,
    text: "Wireshark",
  },

  {
    icon: Docker,
    text: "Docker",
  },
  {
    icon: Kubernetes,
    text: "Kubernetes",
  },
  {
    icon: Auth0,
    text: "Auth0",
  },
  {
    icon: Aws,
    text: "AWS",
  },
  {
    icon: Gcp,
    text: "GCP",
  },
  {
    icon: Okta,
    text: "Okta",
  },
  {
    icon: Xcode,
    text: "Xcode",
  },
];

const programmingLanguages = [
  {
    icon: Python,
    text: "Python",
  },
  {
    icon: JavaScript,
    text: "JavaScript",
  },
  {
    icon: Rust,
    text: "Rust",
  },
  {
    icon: C,
    text: "C",
  },
  {
    icon: Bash,
    text: "Bash",
  },
  {
    icon: Swift,
    text: "Swift",
  },
];

const operatingSystems = [
  {
    icon: Linux,
    text: "Linux",
  },
  {
    icon: KaliLinux,
    text: "Kali Linux",
  },
  {
    icon: Android,
    text: "Android",
  },
  {
    icon: Windows,
    text: "Windows & Active Directory",
  },
  {
    icon: Ios,
    text: "iOS",
  },
  {
    icon: Macos,
    text: "macOS",
  },
];

const linksList = [
  { text: "Home", icon: "fas fa-home", href: "#hero", key: "l1" },
  { text: "About", icon: "fas fa-address-card", href: "#about", key: "l2" },
  {
    text: "Courses",
    icon: "fas fa-award",
    href: "#courses",
    key: "l3",
  },

  { text: "Contact", icon: "fas fa-paper-plane", href: "#contact", key: "l5" },
];

const coursesImages = [
  {
    image: Course01,
    alt: "Crash Course on Python, by Google",
  },
  {
    image: Course02,
    alt: "Technical Support Fundamentals, by Google",
  },
  {
    image: Course03,
    alt: "Introduction to Cybersecurity Tools & Cyber Attacks, by IBM",
  },
  {
    image: Course04,
    alt: "Introduction to Computer Science and Programming Using Python, by MITx",
  },
  {
    image: Course05,
    alt: "Unix Badge, by PentesterLab",
  },
  {
    image: Course06,
    alt: "Recon Badge, by PentesterLab",
  },
  {
    image: Course07,
    alt: "PCAP Badge, by PentesterLab",
  },
  {
    image: Course08,
    alt: "Linux Privilege Escalation for Beginners, by TCM Security",
  },
  {
    image: Course09,
    alt: "Kali Linux Fundamentals, by Cybrary",
  },
  {
    image: Course10,
    alt: "External Pentest Playbook, by TCM Security",
  },
  {
    image: Course11,
    alt: "Practical Ethical Hacking - The Complete Course, by TCM Security",
  },
];

const traits = [
  {
    title: "Self-taught",
    icon: "fas fa-graduation-cap traits__i-taught",
    content:
      "I've always loved to learn, and thanks to the Internet I was able to do it from the comfort of my own home, which eventually led me to discover my biggest passion: Cybersecurity.",
  },
  {
    title: "Avid reader",
    icon: "fab fa-readme traits__i-reader",
    content:
      "I love reading from all type of sources, be it books, documentation, or code.",
  },
  {
    title: "Analytical",
    icon: "far fa-chart-bar traits__i-analytical",
    content:
      "Analyzing every single detail has become one of my main strengths.",
  },
  {
    title: "Coder",
    icon: "fas fa-laptop-code traits__i-coder",
    content:
      "I'm always nurturing this skill by creating new things, or reading someone else's code.",
  },
  {
    title: "Calm",
    icon: "fas fa-leaf traits__i-calm",
    content:
      "I've always considered myself to be good at remaining calm during stressful situations.",
  },
];

const languagesObj = {
  maxLevelArr: [1, 2, 3, 4, 5],
  languages: [
    {
      langName: "Spanish",
      level: "5",
      lvlName: "Native Proficiency",
      img: Spanish,
      imgRatio: { height: 80, width: 80 },
    },
    {
      langName: "English",
      level: "5",
      lvlName: "Bilingual Proficiency",
      img: English,
      imgRatio: { height: 80, width: 80 },
    },
    {
      langName: "Italian",
      level: "2",
      lvlName: "Limited Working Proficiency",
      img: Italian,
      imgRatio: { height: 62.5, width: 80 },
    },
  ],
};

export {
  linksList,
  coursesImages,
  traits,
  technologies,
  programmingLanguages,
  operatingSystems,
  languagesObj,
};
