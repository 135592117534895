import React from "react";
import { Carousel } from "react-bootstrap";
import { coursesImages } from "../../../Data";

const CoursesCarousel = () => {
  return (
    <Carousel touch={true} className="carousel" data-aos="fade-up">
      {coursesImages.map(({ image, alt }, i) => (
        <Carousel.Item key={`carousel${i}`} interval={2000}>
          <div className="carousel__img-box">
            <img
              width={900}
              height={695}
              className="carousel__img"
              src={image}
              alt={`${alt} slide`}
            />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CoursesCarousel;
