import React from "react";
import CoursesCarousel from "./CoursesCarousel";
import Header from "../../UI/Header";

const Courses = () => {
  return (
    <section className="courses" id="courses">
      <Header title="Courses Taken" subtitle="courses" />
      <CoursesCarousel />
    </section>
  );
};

export default Courses;
