import React from "react";

const LevelPoints = ({ arr, level, language }) => (
  <div className="level">
    {arr.map((num) => (
      <div
        className={`level__point level__point--${language} level__point--${num} ${
          num <= level ? "level__colored" : ""
        }`}
        key={language + num}
      ></div>
    ))}
  </div>
);

export default LevelPoints;
