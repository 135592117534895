import { Fragment, useEffect, useState } from "react";
import Banner from "./components/banner/Banner";
import Footer from "./components/footer/Footer";
import Sections from "./components/Sections/Sections";

function App() {
  const [isBannerShown, setIsBannerShown] = useState(true);
  const [isBannerActive, setIsBannerActive] = useState(false);

  const closeBanner = () => setIsBannerShown(false);

  useEffect(() => {
    setTimeout(() => {
      setIsBannerActive(true);
    }, 2000);
  }, []);

  return (
    <Fragment>
      {isBannerShown && (
        <Banner closeBanner={closeBanner} className={isBannerActive} />
      )}

      <Sections />
      <Footer />
    </Fragment>
  );
}

export default App;
