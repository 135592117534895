import Icon from "../UI/Icon";
import { linksList } from "../../Data";
import wolf from "../../imgs/wolf.png";
import { useEffect, useState } from "react";

const Navbar = () => {
  const [isShrunk, setShrunk] = useState(false);
  const [isNavbarSet, setIsNavbarSet] = useState(false);
  const [currentSection, sestCurrentSection] = useState("hero");

  // TO HELP WITH DELAY IN NAVBAR'S PSEUDO ELEMENTS
  useEffect(() => {
    setTimeout(() => {
      setIsNavbarSet(true);
    }, 2000);
  }, []);

  // TO ACTIVATE LINKS WHEN SCROLLING
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    const navbarHandler = () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.pageYOffset >= sectionTop - 80) {
          sestCurrentSection(section.getAttribute("id"));
        }
      });
    };

    window.addEventListener("scroll", navbarHandler);
    return () => window.removeEventListener("scroll", navbarHandler);
  }, [currentSection]);

  // TO SHRINK NAVBAR WHEN SCROLLING DOWN
  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isShrunk;
      });
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, [isShrunk]);

  return (
    <nav
      className={`navbar ${isShrunk ? "shrank-navbar" : ""} ${
        isNavbarSet ? "set-navbar" : ""
      }`}
    >
      <div data-aos="fade-right" data-aos-delay="800">
        <img
          className="navbar__img"
          src={wolf}
          alt="wolf"
          height="100px"
          width="100px"
        />
      </div>

      <ul className="navbar__list" data-aos="fade-left" data-aos-delay="800">
        {linksList.map(({ text, icon, href, key }, i) => {
          return (
            <li
              className={`navbar__item ${
                href.includes(currentSection) ? "navbar__item--active" : ""
              }`}
              key={key}
            >
              <a className="navbar__link" href={href}>
                <Icon icon={icon} />
                <span className="navbar__span">{text}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
