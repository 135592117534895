import React from "react";

import LevelPoints from "../../UI/LevelPoints";
import { languagesObj } from "../../../Data";

const Languages = () => (
  <div className="languages u-mbm" data-aos="fade-up">
    <h3 className="languages__title">Languages</h3>
    <div className="languages__container">
      {languagesObj.languages.map((lang) => (
        <div className="languages__item" key={lang.langName}>
          <img
            src={lang.img}
            alt="spanish language"
            width={lang.imgRatio.width}
            height={lang.imgRatio.height}
          />
          <div className="languages__level-box">
            <span>{lang.langName}</span>
            <p>{lang.lvlName}</p>
            <LevelPoints
              arr={languagesObj.maxLevelArr}
              level={lang.level}
              language={lang.langName}
            />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Languages;
