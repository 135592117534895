import React from "react";
import Icon from "../../UI/Icon";
import Link from "../../UI/Link";

const Contact = () => {
  return (
    <section className="contact" id="contact">
      <div className="contact__heading" data-aos="fade-up">
        <p>Let's meet</p>
        <h2>Contact me</h2>
        <p>Get in touch with me using the links below</p>
      </div>
      <ul className="contact__list" data-aos="fade-up">
        <li className="contact__item">
          <Link
            className="contact__link"
            href="https://www.linkedin.com/in/santiagopassafiume/"
          >
            <Icon icon={"fab fa-linkedin"} />
            LinkedIn
          </Link>
        </li>
        <li className="contact__item">
          <Link
            className="contact__link"
            href="https://github.com/SantiagoPassafiume"
          >
            <Icon icon={"fab fa-github-alt"} />
            GitHub
          </Link>
        </li>
        <li className="contact__item">
          <Link
            className="contact__link"
            href="mailto:santiagopassafiume@protonmail.com"
          >
            <Icon icon={"fas fa-at"} />
            send me an e-mail
          </Link>
        </li>
      </ul>
    </section>
  );
};

export default Contact;
