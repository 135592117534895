import React from "react";
import { traits } from "../../../Data";

const Traits = () => (
  <ul className="traits">
    {traits.map(({ title, icon, content }, i) => (
      <li key={Math.random()} className={`traits__box traits__box--${i + 1}`}>
        <div className="traits__header svg-container">
          <i className={icon}></i>
          <h4 className="link-svgline">
            <span>
              {title}
              <svg
                className="link-svgline"
                viewBox="0 0 400 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="svg_line"
                  d="m 1.986,8.91 c 55.429038,4.081 111.58111,5.822 167.11781,2.867 22.70911,-1.208 45.39828,-0.601 68.126,-0.778 28.38173,-0.223 56.76079,-1.024 85.13721,-1.33 24.17379,-0.261 48.42731,0.571 72.58115,0.571"
                ></path>
              </svg>
            </span>
          </h4>
        </div>

        <div className="traits__text">
          <i className="fas fa-quote-left"></i> <p>{content}</p>
        </div>
      </li>
    ))}
  </ul>
);

export default Traits;
