import React, { Fragment } from "react";
import About from "./about/About";

import Contact from "./contact/Contact";
import Courses from "./Courses/Courses";
import Hero from "./Hero/Hero";

const Sections = () => {
  return (
    <Fragment>
      <Hero id="hero" />
      <About id="about" />
      <Courses id="courses" />
      <Contact id="contact" />
    </Fragment>
  );
};

export default Sections;
