import React from "react";

const Vignettes = () => {
  return (
    <div className="vignettes" data-aos="fade-up" data-aos-duration="1000">
      <div className="vignettes__box">
        <p className="vignettes__p vignettes__p--1" data-aos="flip-left">
          I'm an Application Security Engineer who also works as an Independent
          Contractor (Penetration Tester).
        </p>
        <p className="vignettes__p vignettes__p--2" data-aos="flip-left">
          I started my career as a programmer, but quickly realized that
          programming without security knowledge is bound to fail.
        </p>
      </div>
    </div>
  );
};

export default Vignettes;
