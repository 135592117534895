import React from "react";

const Header = ({ title, subtitle, className }) => (
  <header className={`header ${className}`} data-aos="fade-up">
    <span className="subheading">{subtitle}</span>
    <h2 className="heading">{title}</h2>
  </header>
);

export default Header;
