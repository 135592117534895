import React from "react";
import Typing from "../../UI/Typing";
import Navbar from "../../Navbar/Navbar";

const Hero = () => {
  return (
    <section id="hero" className="hero">
      <Navbar />

      <div className="hero__content">
        <div
          className="hero__context-box"
          data-aos="fade-up"
          data-aos-delay="800"
        >
          <p className="hero__greeting">Hello there!</p>

          <div className="hero__heading-box">
            <h1 className="hero__title">
              <span className="hero__title--first">I'm</span>
              <span className="hero__title--second">Santiago Passafiume</span>
            </h1>

            <p className="hero__professions">
              <span className="hero__typing">
                <Typing
                  words={[
                    "an Application Security Engineer",
                    "a Penetration Tester",
                    "a Backend Developer",
                  ]}
                  cursorType={"|"}
                />
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
