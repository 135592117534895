import React from "react";

const IconList = ({ list }) => {
  return (
    <ul className="i-list">
      {list.map(({ text, icon }) => (
        <li className="i-list__item" key={Math.random().toString()}>
          <div className="i-list__item--img-container">
            <img
              alt={`${icon} icon`}
              src={icon}
              style={{ width: "50px", height: "50px" }}
              className="i-list__item--img"
            />
          </div>
          <span className="i-list__item--text">{text}</span>
        </li>
      ))}
    </ul>
  );
};

export default IconList;
